import { createRouter, createWebHistory } from 'vue-router';

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "home" */ '@/pages/Home'),
    },
    {
      path: '/draw-details',
      name: 'DrawDetails',
      component: () => import(/* webpackChunkName: "drawDetails" */ '@/components/DrawDetails'),
    },
    {
      path: '/tickets-history',
      name: 'TicketsHistory',
      component: () => import(/* webpackChunkName: "ticketsHistory" */ '@/components/TicketsHistory'),
    },
    {
      path: '/results',
      name: 'TerminalResults',
      component: () => import(/* webpackChunkName: "TerminalResults" */ '@/layouts/TerminalResults'),
    },
    {
      path: '/ticket-details',
      name: 'TerminalTicketDetails',
      component: () => import(/* webpackChunkName: "TerminalTicketDetails" */ '@/layouts/TerminalTicketDetails'),
    },
    {
      path: '/ticket-history',
      name: 'TerminalTicketHistory',
      component: () => import(/* webpackChunkName: "TerminalTicketHistory" */ '@/layouts/TerminalTicketHistory'),
    },
  ],
});
