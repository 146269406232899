export default {
  convert(h, s, l) {
    const lightness = l / 100;
    const a = s * (Math.min(lightness, 1 - lightness) / 100);
    const f = (n) => {
      const k = (n + h / 30) % 12;
      const color = lightness - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, '0');
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  },
};
