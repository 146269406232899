export default {
  config: {},
  gameLayout: 'Expanded',
  gameChannelType: 'Web',
  visualizationV2Enabled: false,
  visualizationPlugin: {
    origin: {
      v1: {
        cf: {
          development: 'https://dev.luckyx-visualization.nsoft.app',
          staging: 'https://staging.luckyx-visualization.nsoft.app',
          production: 'https://luckyx-visualization.nsoft.app',
        },
        netlify: {
          development: 'https://dev.luckyx-visualization.7platform.net',
          staging: 'https://staging.luckyx-visualization.7platform.net',
          production: 'https://luckyx-visualization.7platform.net',
        },
      },
      v2: {
        cf: {
          development: 'https://dev.draw-visualization.nsoft.app',
          staging: 'https://staging.draw-visualization.nsoft.app',
          production: 'https://draw-visualization.nsoft.app',
        },
        netlify: {
          development: 'https://dev.draw-visualization.7platform.net',
          staging: 'https://staging.draw-visualization.7platform.net',
          production: 'https://draw-visualization.7platform.net',
        },
      },
    },
    clientType: 'user',
    clientSubType: 'Player',
    encoding: {
      default: 'plaintext',
    },
  },
  gcmApi: {
    staging: ['https://gcm-fra-staging-1.7platform.com:8008', 'https://gcm-fra-staging-2.7platform.com:8008'],
    production: [
      'https://gcm-fra-1.7platform.com:8008',
      'https://gcm-fra-2.7platform.com:8008',
      'https://gcm-fra-3.7platform.com:8008',
    ],
  },
  jackpotState: undefined,
  showBackButton: true,
  isAudioOn: false,
  roundId: null,
  // mode: 'standalone', // move to games-static
  currentDrawnBall: null,
  bettingRules: {
    minBingoNumber: 1,
    maxBingoNumber: 50,
    minBallSelection: 1,
    maxBallSelection: 10,
    bingoColors: ['#DD1F1F', '#2962FF', '#67B627', '#FFE400', '#8C00B4'],
  },
  bettingData: {
    numbersBet: {},
    selectedBet: {},
    preballBet: {},
    colorBet: {},
    selectedBetNumbers: [],
    selectedColor: {},
    selectedSpecialBet: {},
    firstBallColorBet: {},
    bettingDisabled: false, // used to lock betting area and betslip
    systemNum: 0,
  },
  isBettingDisabled: false, // betting disabled event
  gameState: {},
  specialBets: [],
  normalBets: [],
  isBetslipOpen: true,
  showInfoModal: false,
  resultsData: [],
  player: {
    username: '',
    id: '',
    loggedIn: false,
    auth: {
      token: 'xx',
    },
    balance: {
      time: null,
      balanceType: '',
      balanceAmt: 0,
      currency: '',
      divide: 1,
      locale: 'en-EN',
    },
    audio: false,
  },
  isonisList: ['MNE'],
  stats: {
    lastId: 0,
    drawnMostTimes: [],
    drawnLeastTimes: [],
    firstBallOddEven: {
      even: '',
      odd: '',
    },
    firstBallLowHigh: {
      low: '',
      high: '',
    },
    preballsMoreOddEven: null,
    preballsSumLowHigh: {
      low: '',
      high: '',
      equal: '',
    },
    firstBallColor: {
      red: 0,
      blue: 0,
      green: 0,
      yellow: 0,
      purple: 0,
    },
  },
  activeComponent: null,
  layout: null,
  isTerminal: false,
  terminalUserData: null,
  device: null,
  betshop: null,
  companyData: null,
  account: null,
  ticketDetailsData: null,
  isLoaded: false,
  arrowsVisible: false,
  ticketHistoryTerminal: [],
  nextRoundTime: null,
  currentEventId: null,
  roundInProgress: false,
  ticketToRebet: null,
  timer: null,
  quickpickCurrentValue: null,
  showFreeBetModal: false,
  terminalMouseCursorEnabled: true,
  showFreeBetExpiredModal: false,
};
