import store from '../store';
import { http } from './http';

export default {
  getTicketHistory(data) {
    const { productId, device } = store.getters.config;
    const { timeFrom, timeTo, count } = data;
    const path = process.env.VUE_APP_TERMINAL_TICKET_HISTORY_PATH;

    return http
      .get(
        `${path}/${productId}.json?deviceUuid=${device.deviceUuid}&timeFrom=${timeFrom}&timeTo=${timeTo}&count=${count}`,
      )
      .then((response) => response);
  },
  checkTicketByBarcode(barcode) {
    const { terminalUserData } = store.getters;
    const url =
      terminalUserData.type === 'ANONYMOUS'
        ? process.env.VUE_APP_TERMINAL_TICKET_CHECK_PATH
        : process.env.VUE_APP_TERMINAL_ADMIN_TICKET_CHECK_PATH;

    return http.get(`${url}/${barcode}.json`).then((response) => response);
  },
};
