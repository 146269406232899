/* eslint-disable no-param-reassign */
import axios from 'axios';
import store from '../store';

/*
Change baseURL to suit your project needs.
*/
const http = axios.create({
  timeout: 5000,
});
const httpStreamMapping = axios.create({
  baseURL: process.env.VUE_APP_STREAM_MAPPING_API,
  timeout: 5000,
});

http.interceptors.request.use((config) => {
  const { uuid, password } = store.getters.account;
  const { device, config: gameConfig } = store.getters;
  const token = store.getters.terminalUserData?.auth?.token ?? store.getters.terminalUserData?.token;

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
    config.headers['http-x-nab-betshop-id'] = gameConfig.betshop.id;
    config.headers['http-x-nab-company-id'] = gameConfig.companyData.id;
    config.headers['http-x-nab-device-uuid'] = device.uuid;
    config.headers['http-x-nab-dp'] = 'AdminTerminal';
    config.headers['seven-locale'] = gameConfig.locale;
    config.headers['x-nsft-seven-company-uuid'] = gameConfig.companyData.uuid;
  } else if (uuid && password) {
    const basicAuthToken = btoa(`${uuid}:${password}`);
    config.headers['Authorization'] = `Basic ${basicAuthToken}`;
    config.headers['seven-locale'] = gameConfig.locale;
  }
  return config;
});

export { http, httpStreamMapping };
