import types from './mutationTypes';
import { helpers } from '@nsftx/games-sdk-js/src/utility/index';
import { findIndex, assign } from 'lodash';

const betStructure = {
  market: '',
  outcome: '',
  odd: null,
  roundNumber: null,
  numEvents: 1,
};

export default {
  [types.SET_CONFIG](state, config) {
    state.config = config;
  },
  [types.SET_BET_LIMITS](state, limits) {
    state.config.betLimit = limits;
  },
  [types.SET_NORMAL_BETS](state, payload) {
    state.normalBets = payload;
  },
  [types.SET_SPECIAL_BETS](state, payload) {
    state.specialBets = payload;
  },
  [types.RESET_SPECIAL](state) {
    state.bettingData.selectedSpecialBet = {};
  },
  [types.RESET_SYSTEM_NUM](state) {
    state.bettingData.systemNum = 0;
  },
  [types.RESET_FIRST_BALL_COLOR](state) {
    state.bettingData.firstBallColorBet = {};
  },
  [types.RESET_PREBALL_BET](state) {
    state.bettingData.preballBet = {};
  },
  [types.RESET_SELECTED_COLOR](state) {
    state.bettingData.selectedColor = {};
  },
  [types.RESET_SELECTED_BET_NUMBERS](state) {
    state.bettingData.selectedBetNumbers = [];
  },
  [types.TOGGLE_BET_NUMBERS](state, betNumbers) {
    const { bettingData } = state;
    bettingData.selectedBetNumbers = betNumbers;
    const isValidBet = betNumbers.length >= state.bettingRules.minBallSelection;
    const { bets } = state.config;
    let combinations = null;
    let outcome, cleanOutcome;

    if (isValidBet && state.bettingData.systemNum) {
      combinations = helpers.getCombinations(betNumbers.length, state.bettingData.systemNum);
    }
    const betValueLength = combinations ? bettingData.systemNum : betNumbers.length;

    if (betNumbers.length > 1) {
      outcome = betNumbers
        .map(({ value }) => value)
        .sort((a, b) => a - b)
        .join(' ');
      cleanOutcome = betNumbers.map(({ value }) => value).sort((a, b) => a - b);
    } else {
      outcome = String(betNumbers.map(({ value }) => value));
      cleanOutcome = betNumbers.map(({ value }) => value);
    }

    bettingData.numbersBet = {
      ...betStructure,
      betId: combinations ? betValueLength + 10 : betValueLength,
      type: combinations ? betValueLength + 10 : betValueLength,
      market: combinations ? bets[betValueLength + 10].name : bets[betValueLength].name,
      outcome,
      category: 'Standard',
      cleanOutcome,
      valid: isValidBet,
      roundNumber: state.roundId,
      combinations,
    };
    bettingData.selectedBet = bettingData.numbersBet;
  },
  [types.TOGGLE_COLORS](state, colorGroup) {
    const colorKey = findIndex(state.bettingRules.bingoColors, (color) => color === colorGroup.color);
    const { translations } = state.translations;
    state.bettingData.colorBet = {
      ...betStructure,
      ...colorGroup,
      category: 'Standard',
      market: translations[`luckyx_color_${colorKey}`],
      outcome: state.bettingData.selectedBetNumbers.map(({ value }) => value).join(' '),
      cleanOutcome: state.bettingData.selectedBetNumbers.map(({ value }) => value),
      roundNumber: state.roundId,
      valid: colorGroup.checked,
      betId: 10,
      type: 10,
    };
    state.bettingData.selectedBet = colorGroup.checked ? state.bettingData.colorBet : {};
  },
  [types.TOGGLE_FIRST_BALL_COLOR](state, { bet, outcome }) {
    const calcOutcome = () => {
      const idx = findIndex(bet.outcomes, (o) => o === outcome);
      let k = parseInt(idx, 10);
      k = k === 4 ? 0 : k + 1;
      return 2 ** k;
    };

    const { odds } = bet;
    state.bettingData.firstBallColorBet = {
      ...betStructure,
      category: 'Special',
      betId: bet.id,
      type: bet.id,
      market: bet.name,
      outcome: String(outcome),
      odds,
      cleanOutcome: outcome,
      outcomeIndex: calcOutcome(),
      roundNumber: state.roundId,
      valid: true,
    };

    state.bettingData.selectedBet = state.bettingData.firstBallColorBet;
  },
  [types.TOGGLE_SPECIAL_BETS](state, { specialBet, outcomeIndex }) {
    if (specialBet) {
      const odds = specialBet.odd || specialBet.odds;
      state.bettingData.selectedSpecialBet = {
        ...betStructure,
        category: 'Special',
        betId: specialBet.id,
        type: specialBet.id,
        market: specialBet.name,
        outcome: specialBet.outcomes ? String(specialBet.outcomes[outcomeIndex]) : '',
        odds,
        cleanOutcome: specialBet.outcomes ? String(specialBet.outcomes[outcomeIndex]) : '',
        outcomeIndex,
        roundNumber: state.roundId,
        valid: !!specialBet.outcomes,
      };
      state.bettingData.selectedBet = state.bettingData.selectedSpecialBet;
    }
  },
  [types.TOGGLE_PREBALL_BET](state, active) {
    const market = state.bettingData.selectedBetNumbers[0];
    const { translations } = state.translations;
    const odds = Number(state.config.bets[23].odds);
    state.bettingData.preballBet = {
      ...betStructure,
      betId: 23,
      type: 23,
      category: 'Special',
      market: translations.general_preball_6,
      outcome: market ? String(market.value) : '',
      odds,
      cleanOutcome: market ? market.value : '',
      active,
      roundNumber: state.roundId,
      valid: !!active && !!market,
    };
    state.bettingData.selectedBet = active && market ? state.bettingData.preballBet : {};
  },
  [types.SET_SELECTED_COLOR](state, payload) {
    state.bettingData.selectedColor = payload;
  },
  [types.BETTING_DISABLE](state, disable) {
    state.bettingData.bettingDisabled = disable;
  },
  [types.SET_IS_BETSLIP_OPEN](state, isBetslipOpen) {
    state.isBetslipOpen = isBetslipOpen;
  },
  [types.SET_JACKPOT_STATE](state, jackpotState) {
    state.jackpotState = jackpotState;
  },
  [types.TOGGLE_MODAL_INFO](state) {
    state.showInfoModal = !state.showInfoModal;
  },
  [types.SET_PRODUCT_RESULTS](state, data) {
    state.resultsData = [];
    state.resultsData = data;
  },
  [types.SET_PRODUCT_STATS](state, statsData) {
    state.stats = {};
    state.stats = statsData;
  },
  [types.CLEAR_SELECTED_BET](state) {
    state.bettingData.selectedBet = {};
  },
  [types.SET_GAME_STATE](state, gameState) {
    state.gameState = gameState;
  },
  [types.UPDATE_GAME_STATE](state, gameState) {
    assign(state.gameState, gameState);
    state.currentDrawnBall = gameState.ball;
  },
  [types.UPDATE_ROUND](state, roundId) {
    state.roundId = roundId;
  },
  [types.SET_SYSTEM_NUMBER](state, numOfSystem) {
    state.bettingData.systemNum = numOfSystem;
  },
  [types.SET_IS_BETTING_DISABLED](state, payload) {
    state.isBettingDisabled = payload;
  },
  [types.RESET_USER](state) {
    state.user.user = {
      auth: {
        token: null,
      },
      balance: 0,
      profile: {
        id: null,
        email: null,
        firstName: null,
        lastName: null,
        balance: 0,
      },
      bonuses: [],
    };
  },
  [types.SET_ACTIVE_COMPONENT](state, payload) {
    state.activeComponent = payload;
  },
  [types.SET_LAYOUT](state, payload) {
    state.layout = payload;
  },
  [types.SET_IS_TERMINAL](state, payload) {
    state.isTerminal = payload;
  },
  [types.SET_TERMINAL_USER_DATA](state, payload) {
    state.terminalUserData = payload;
  },
  [types.SET_DEVICE_DATA](state, payload) {
    state.device = payload;
  },
  [types.SET_BETSHOP_DATA](state, payload) {
    state.betshop = payload;
  },
  [types.SET_COMPANY_DATA](state, payload) {
    state.companyData = payload;
  },
  [types.SET_ACCOUNT_DATA](state, payload) {
    state.account = payload;
  },
  [types.SET_TICKET_DETAILS_DATA](state, payload) {
    state.ticketDetailsData = payload;
  },
  [types.SET_IS_LOADED](state, payload) {
    state.isLoaded = payload;
  },
  [types.SET_TICKET_HISTORY_TERMINAL](state, payload) {
    state.ticketHistoryTerminal = payload;
  },
  [types.SET_ARROWS_VISIBILITY](state, payload) {
    state.arrowsVisibility = payload;
  },
  [types.SET_NEXT_ROUND_TIME](state, payload) {
    state.nextRoundTime = payload;
  },
  [types.SET_CURRENT_EVENT_ID](state, payload) {
    state.currentEventId = payload;
  },
  [types.SET_ROUND_IN_PROGRESS](state, payload) {
    state.roundInProgress = payload;
  },
  [types.SET_TICKET_TO_REBET](state, payload) {
    state.ticketToRebet = payload;
  },
  [types.SET_TIMER](state, payload) {
    state.timer = payload;
  },
  [types.SET_QUICKPICK_CURRENT_VALUE](state, value) {
    state.quickpickCurrentValue = value;
  },
  [types.TOGGLE_FREE_BET_MODAL](state, show) {
    state.showFreeBetModal = show;
  },
};
