import { createStore } from 'vuex';

export default {
  install: function install(app) {
    app.component('LocalizedLabel', {
      name: 'LocalizedLabel',
      functional: true,
      props: {
        translation: {
          type: Object,
          required: false,
          default: () => {},
        },
      },
      render(createElement, ctx) {
        const key = ctx.props.translation;
        const store = createStore().store;
        const translation = store.getters.translations[key];
        return createElement('span', { class: '' }, translation);
      },
    });
  },
};
