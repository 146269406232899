import store from '../store';
import { http } from './http';

export default {
  async getTicketHistory(data) {
    const { productId, device } = store.getters.config;
    const { timeFrom, timeTo, count } = data;
    const path = process.env.VUE_APP_TERMINAL_TICKET_HISTORY_PATH;

    return await http.get(
      `${path}/${productId}.json?deviceUuid=${device.deviceUuid}&timeFrom=${timeFrom}&timeTo=${timeTo}&count=${count}`,
    );
  },
  async checkTicketByBarcode(barcode) {
    const { terminalUserData } = store.getters;
    const url =
      terminalUserData.type === 'ANONYMOUS'
        ? process.env.VUE_APP_TERMINAL_TICKET_CHECK_PATH
        : process.env.VUE_APP_TERMINAL_ADMIN_TICKET_CHECK_PATH;

    return await http.get(`${url}/${barcode}.json`);
  },
  async checkTicketByProviderBetslipId(providerBetslipId) {
    const baseURL = process.env.VUE_APP_NGS_API;
    const { config } = store.getters;
    const user = store.getters['user/user'];
    const token = user?.auth?.token;
    const ticketCheckPath = this.interpolateUrl(process.env.VUE_APP_NEXTGEN_WEB_TICKET_CHECK_PATH, {
      requestUuid: providerBetslipId,
    });
    return await http.get(`${baseURL}${ticketCheckPath}?product=LuckyX`, {
      headers: {
        Authorization: `Bearer: ${token}`,
        'X-Nsft-Ngs-Company': config.tenantId,
        'X-Nsft-Ngs-Product': config.productId,
        'X-Request-ID': providerBetslipId,
      },
    });
  },
  // USED TO POPULATE VALUES INSIDE {} curly brackets in url
  interpolateUrl(url, config) {
    return url.replace(/{([^}]+)}/g, (match, key) =>
      Object.prototype.hasOwnProperty.call(config, key) ? config[key] : match,
    );
  },
};
