import { toLower } from 'lodash';
import axios from 'axios';

const http = axios.create({
  timeout: 10000,
});

export default {
  async getJackpot(config) {
    const channelName = toLower(config.applicationName) || 'web';
    const channelNameMapper = {
      mobile: 'mob',
      web: 'web',
      terminal: 'terminal',
    };
    const channelIds = {
      mobile: 4,
      terminal: 2,
      web: 3,
    };

    const response = await http.get('/open/nonstandalone', {
      baseURL: process.env.VUE_APP_GAMES_AJS_API,
      data: {
        isJackpotRequest: true,
      },
      params: {
        account: config.tenantId,
        game: config.productId,
        channel: channelIds[channelName],
        betshop: channelNameMapper[channelName],
        location: channelNameMapper[channelName],
      },
    });

    return response.data;
  },
};
