export default {
  sendMessage(data, delay, targetFrame) {
    if ('parentIFrame' in window) {
      setTimeout(() => {
        window.parentIFrame.sendMessage(data);
      }, delay || 0);
    } else {
      window.postMessage(data);
      if (targetFrame) {
        targetFrame.contentWindow.postMessage(data, '*');
      }
    }
  },
};
