import busService from './busService';
import store from '../store';
import { isArray } from 'lodash';

const handleTerminalTicket = (ticket, eventDetail) => {
  switch (ticket.status.id) {
    case '0': {
      handleAcceptedTicket(ticket);
      break;
    }
    case '1': {
      handleCanceledTicket(ticket, eventDetail);
      break;
    }
    case '5': {
      handlePaidOutTicket(ticket, eventDetail);
      break;
    }
    default:
  }
};

const handleAcceptedTicket = (ticket) => {
  ticket.bets.forEach((bet) => {
    // eslint-disable-next-line no-param-reassign
    if (bet.odd) bet.displayOdd = bet.odd; // Adjust to v1 template in production
  });
  const additionalData = {
    clientPrintJobIdentifier: {
      id: 'TicketPayIn',
      uuid: ticket.requestUuid,
    },
  };
  busService.sendMessage('Peripherals.Print', {
    type: 'ticket',
    productId: 'LuckyX',
    data: ticket,
    additionalData,
  });
  busService.sendMessage('Dialog.Show', {
    message: store.getters.translations['ticket.add_success'],
    type: 'success',
    delay: 3000,
  });
};

const handleCanceledTicket = (ticket, eventDetail) => {
  busService.sendMessage('Tickets.Update', {
    action: 'Cancel',
    ticketData: ticket,
    showMessage: false,
  });
  busService.sendMessage('Dialog.Show', {
    message: store.getters.translations['ticket.cancel_success_to_balance'].replace(
      '{{amount}}',
      eventDetail.cancelledAmount.toFixed(2),
    ),
    type: 'success',
    delay: 3000,
  });
  store.dispatch('setTicketDetailsData', ticket);
};

const handlePaidOutTicket = (ticket, eventDetail) => {
  const { productName } = store.getters.config;
  const { userIsOperator } = store.getters;
  if (userIsOperator) {
    busService.sendMessage('Peripherals.Print', {
      layoutName: `ticket${productName}Payout`,
      productId: productName,
      data: ticket,
    });
  }
  busService.sendMessage('Dialog.Show', {
    message: store.getters.translations['ticket.payout_success_to_balance'].replace(
      '{{amount}}',
      eventDetail.payout.toFixed(2),
    ),
    type: 'success',
    delay: 3000,
  });
  store.dispatch('setTicketDetailsData', ticket);
};

const handleTicketUpdateError = (payload) => {
  const { message } = payload;
  busService.sendMessage('Dialog.Show', {
    message: message,
    type: 'warning',
    delay: 3000,
  });
};

const mapRebet = (ticket) => {
  const bets = [];
  ticket.bets.forEach((bet) => {
    bets.push({
      round: bet.eventId,
      market: bet.bet.title,
      outcome: isArray(bet.value) ? bet.value.join(' ') : bet.outcome.title,
      outcomeIndex: isArray(bet.value) ? null : bet.outcome.value,
      stake: bet.amount,
      odds: bet.category === 'SPECIAL' ? bet.odd : null,
      type: bet.type,
      tax: bet.tax,
    });
  });
  return bets;
};

export default {
  handleTerminalTicket,
  handleTicketUpdateError,
  mapRebet,
};
