export default {
  SET_CONFIG: 'SET_CONFIG',
  SET_GAME_STATE: 'SET_GAME_STATE',
  SET_BET_LIMITS: 'SET_BET_LIMITS',
  SET_NORMAL_BETS: 'SET_NORMAL_BETS',
  SET_SPECIAL_BETS: 'SET_SPECIAL_BETS',
  UPDATE_GAME_STATE: 'UPDATE_GAME_STATE',
  RESET_SPECIAL: 'RESET_SPECIAL',
  RESET_FIRST_BALL_COLOR: 'RESET_FIRST_BALL_COLOR',
  RESET_PREBALL_BET: 'RESET_PREBALL_BET',
  RESET_SELECTED_COLOR: 'RESET_SELECTED_COLOR',
  RESET_SELECTED_BET_NUMBERS: 'RESET_SELECTED_BET_NUMBERS',
  RESET_SYSTEM_NUM: 'RESET_SYSTEM_NUM',
  TOGGLE_BET_NUMBERS: 'TOGGLE_BET_NUMBERS',
  TOGGLE_COLORS: 'TOGGLE_COLORS',
  SET_SELECTED_COLOR: 'SET_SELECTED_COLOR',
  TOGGLE_FIRST_BALL_COLOR: 'TOGGLE_FIRST_BALL_COLOR',
  TOGGLE_PREBALL_BET: 'TOGGLE_PREBALL_BET',
  TOGGLE_SPECIAL_BETS: 'TOGGLE_SPECIAL_BETS',
  BETTING_DISABLE: 'BETTING_DISABLE',
  SET_IS_BETSLIP_OPEN: 'SET_IS_BETSLIP_OPEN',
  SET_JACKPOT_STATE: 'SET_JACKPOT_STATE',
  TOGGLE_MODAL_INFO: 'TOGGLE_MODAL_INFO',
  SET_PRODUCT_RESULTS: 'SET_PRODUCT_RESULTS',
  SET_PRODUCT_STATS: 'SET_PRODUCT_STATS',
  CLEAR_SELECTED_BET: 'CLEAR_SELECTED_BET',
  UPDATE_ROUND: 'UPDATE_ROUND',
  SET_SYSTEM_NUMBER: 'SET_SYSTEM_NUMBER',
  SET_IS_BETTING_DISABLED: 'SET_IS_BETTING_DISABLED',
  RESET_USER: 'RESET_USER',
  SET_ACTIVE_COMPONENT: 'SET_ACTIVE_COMPONENT',
  SET_LAYOUT: 'SET_LAYOUT',
  SET_IS_TERMINAL: 'SET_IS_TERMINAL',
  SET_TERMINAL_USER_DATA: 'SET_TERMINAL_USER_DATA',
  SET_DEVICE_DATA: 'SET_DEVICE_DATA',
  SET_BETSHOP_DATA: 'BETSHOP_DATA',
  SET_COMPANY_DATA: 'SET_COMPANY_DATA',
  SET_ACCOUNT_DATA: 'SET_ACCOUNT_DATA',
  SET_TICKET_DETAILS_DATA: 'SET_TICKET_DETAILS_DATA',
  SET_IS_LOADED: 'SET_IS_LOADED',
  SET_TICKET_HISTORY_TERMINAL: 'SET_TICKET_HISTORY_TERMINAL',
  SET_ARROWS_VISIBILITY: 'SET_ARROWS_VISIBILITY',
  SET_NEXT_ROUND_TIME: 'SET_NEXT_ROUND_TIME',
  SET_CURRENT_EVENT_ID: 'SET_CURRENT_EVENT_ID',
  SET_ROUND_IN_PROGRESS: 'SET_ROUND_IN_PROGRESS',
  SET_TICKET_TO_REBET: 'SET_TICKET_TO_REBET',
  SET_TIMER: 'SET_TIMER',
  SET_QUICKPICK_CURRENT_VALUE: 'SET_QUICKPICK_CURRENT_VALUE',
  TOGGLE_FREE_BET_MODAL: 'TOGGLE_FREE_BET_MODAL',
};
